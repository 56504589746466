import Vue, { VNode } from 'vue';

const defaultLayout = 'CeeLayoutDefault';
export default Vue.extend({
    name: 'cee-layout',
    props: {
        name: {
            type: String,
            required: false,
        },
    },
    computed: {
        getName(): string {
            return this.name || defaultLayout;
        },
    },
    created() {
        const components = this.$options.components;
        if (components === null || typeof components === 'undefined' || !components[this.getName]) {
            Vue.component(this.getName, () => import(`@/views/Layouts/${this.getName}.vue`));
        }
        this.$parent.$emit(`update:layout`, this.getName);
    },
    render(): VNode {
        if (this.$slots !== null && this.$slots.default !== null) {
            return this.$slots.default[0];
        }
        return null;
    },
});
